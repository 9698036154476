.default-main {
  padding: 180px 0;
  text-align: left;
  min-height: calc(100vh - 200px);
  background-color: darken($main-color, 3%);
  color: white;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  h1 {
    font-size: 52px;
    margin-top: 100px;
    @media (max-width: 991px) {
      font-size: 36px;
    }
  }

  p, a {
    color: white;
  }
}
