body {
  font-family: 'Montserrat', sans-serif;
}

a {
  color: $main-color;
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
