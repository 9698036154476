* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

section {
  overflow-x: hidden;
}

.section {
  &-desktop {
    @media (max-width: 991px) {
      display: none;
    }
  }
  &-mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

button {
  cursor: pointer;
}
