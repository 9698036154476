.home-howitworks {
  padding: 80px 0 865px;
  position: relative;
  overflow: visible;
  @media (max-width: 991px) {
    padding: 60px 0;
  }

  h2 {
    font-size: 40px;
    color: #1e2e42;
    font-weight: 400;
    margin-top: 0;
    @media (max-width: 991px) {
      text-align: center;
    }
  }

  #how-man {
    position: absolute;
    top: 50px;
    right: 0;
    width: 400px;
  }

  #car-loading {
    position: absolute;
    top: 800px;
    left: 0;
    width: 400px;
  }

  #plants {
    position: absolute;
    top: 875px;
    right: 40px;
    width: 330px;
  }

  .content {
    .step {
      position: absolute;

      p {
        font-size: 20px;
        line-height: 1.27;
        color: #1e2e42;
      }
    }

    #step1 {
      top: 0;
      left: 50%;
      transform: translate(-30%, -25%);

      .screen {
        width: 550px;
      }

      .line {
        position: absolute;
        left: 37%;
        top: 30%;
      }

      p {
        width: 200px;
        position: relative;
        left: 48%;
        transform: translateY(-65%);
      }
    }

    #step2 {
      top: 250px;
      left: 60px;
      @media (min-width: 1500px) {
        left: 50%;
        transform: translateX(-170%);
      }

      .screen {
        width: 390px;
      }

      .line {
        position: absolute;
        left: 56%;
        top: 46%;
      }

      p {
        width: 240px;
        position: absolute;
        left: 104%;
        bottom: 44%;
      }
    }

    #step3 {
      top: 390px;
      right: 270px;
      @media (min-width: 1500px) {
        left: 50%;
        right: auto;
        transform: translateX(60%);
      }

      .screen {
        width: 280px;
      }

      .line {
        position: absolute;
        left: 73%;
        top: 35%;
      }

      p {
        width: 220px;
        position: absolute;
        left: 115%;
        bottom: 55%;
      }
    }

    #step4 {
      top: 650px;
      right: 470px;
      z-index: 1;
      @media (min-width: 1500px) {
        left: 50%;
      }

      .screen {
        width: 250px;
      }

      .line {
        position: absolute;
        right: 83%;
        top: 20%;
      }

      p {
        width: 120px;
        position: absolute;
        right: 93%;
        bottom: 78%;
      }
    }
  }

  .step-mobile {
    margin: 4em 0 2em;

    img {
      max-width: 100%;
    }
    p {
      font-size: 20px;
      line-height: 1.27;
      color: #1e2e42;
      margin: 10px 15px 0;
    }
  }

  #step1-mobile {
    img {
      transform: translateX(15px);
    }
  }

  #step2-mobile {
    img {
      transform: translateX(-15px);
    }
  }
}
