.btn {
  padding: 14px 35px;
  border-radius: 50px;
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  color: #16b899;
  border: 1px solid white;
  transition: 0.2s ease;

  @media (max-width: 575px) {
    padding: 12px 25px;
    max-width: 100%;
    font-size: 18px;
  }

  &:hover {
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.16);
  }

  &-big {
    padding: 17px 45px;
    font-size: 23px;
    @media (max-width: 767px) {
      padding: 14px 35px;
      font-size: 20px;
    }
  }

  &-transparent {
    color: white;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
