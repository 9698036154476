footer {
  background-color: $main-color;
  padding: 65px 0;
  color: white;
  @media (max-width: 767px) {
    text-align: center;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .top {
    text-align: right;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 2em;
    }

    a {
      color: white;
      margin-left: 20px;
      @media (max-width: 767px) {
        margin: 20px 0 10px;
        display: block;
      }
    }
  }

  .bottom {
    margin-top: 2em;
    opacity: 0.5;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #c4c9ce;
  }
}
