.home-banner {
  padding: 0;

  img.banner {
    width: 100%;
    @media (max-width: 767px) {
      width: 200%;
      transform: translateX(-40%);
    }
  }
}
