.home-bepart {
  padding: 120px 0 160px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 100%;

  h2 {
    font-size: 52px;
    color: white;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 26px;
    line-height: 1.38;
    color: white;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    button {
      margin-top: 1em;
    }
  }

  .app-store-buttons {
    margin-top: 2em;
    img {
      margin: 1em;
      height: 60px;
      @media (max-width: 575px) {
        margin: 1em 0.25em;
        height: 60px;
      }
    }
  }
}
