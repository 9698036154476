.home-header {
  padding: 160px 0 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 100%;

  .flex-wrapper {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .left {
    width: 50%;
    @media (max-width: 991px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .right {
    width: 40%;
    padding-top: 40px;
    margin-left: 10%;
    @media (max-width: 991px) {
      width: 100%;
      margin-left: 5%;
    }

    img {
      max-width: 160%;
      @media (min-width: 1500px) {
        max-width: 180%;
      }
      @media (max-width: 991px) {
        max-width: 105%;
      }
    }
  }

  h1 {
    font-size: 68px;
    font-weight: bold;
    line-height: 1.15;
    color: white;
    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.67;
    color: white;
  }

  form#signup {
    position: relative;
    margin-top: 40px;

    @media (min-width: 768px) {
      width: 550px;
      input[type="email"] {
        width: 100%;
        padding-right: 250px;
      }

      button {
        width: 230px;
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 20px;
        padding-left: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    @media (max-width: 767px) {
      button {
        margin-top: 1em;
      }
    }

    input {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(white, 0.5);
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(white, 0.5);
      }

      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(white, 0.5);
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(white, 0.5);
      }
    }
  }

  .app-store-buttons {
    margin-top: 2em;

    img {
      margin: 1em 1em 1em 0;
      height: 60px;
      @media (max-width: 575px) {
        margin: 1em 0.25em 1em 0;
        height: 42px;
      }
    }
  }

  /*
   * Progress Bar
   */
  .progress {
    margin-top: 2em;
    width: 550px;
    max-width: 100%;
    text-align: center;
    color: white;
    background-color: white;
    box-shadow:none;
    border-radius: 50px;
    overflow: hidden;
  }

  .progress-bar {
    background-color: $main-color;
    box-shadow:none;
    padding: 6px 15px;
  }
  @mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  }

  @-webkit-keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }

  // Spec and IE10+
  @keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }

  @mixin animation($animation) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
  }
  .progress.active .progress-bar,
  .progress-bar.active {
    @include animation(progress-bar-stripes 2s linear infinite);
  }
  .progress-striped .progress-bar,
  .progress-bar-striped {
    @include gradient-striped;
    background-size: 40px 40px;
  }
  @mixin progress-bar-variant($color) {
    background-color: $color;
  }
  /*
   * End Progress Bar
   */
}
