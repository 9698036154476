.home-advantages {
  padding: 0 0 80px;
  text-align: center;

  h2 {
    font-size: 40px;
    color: #1e2e42;
    font-weight: 400;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5em;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .advantage {
    width: 30%;
    @media (max-width: 991px) and (min-width: 576px) {
      width: 70%;
      margin: 0 auto;
      margin-top: 20px;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-top: 20px;
    }
    .icon {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 18px;
      line-height: 1.67;
      color: #617283;
    }
  }
}
