.thankyou-main {
  padding: 180px 0;
  text-align: center;
  min-height: calc(100vh - 200px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 100%;
  color: white;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &-small {
      width: 800px;
      max-width: 100%;
    }
  }

  h1 {
    font-size: 52px;
    margin-top: 100px;
    @media (max-width: 991px) {
      font-size: 36px;
    }
  }

  h2 {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 400;
    max-width: 100%;
    width: 1000px;
    margin: 2em auto;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }

  p {
    line-height: 1.5;
    font-size: 18px;
    max-width: 100%;
    width: 1000px;
    margin: 1em auto;
  }

  .question-wrapper {
    text-align: left;

    &-email {
      margin-top: 4em;
      label {
        margin-right: 1em;
      }
    }

    &-submit {
      display: flex;
      align-items: center;
      margin-top: 4em;
    }

    .question {
      font-size: 24px;
      margin: 3em 0 1em;
    }

    .form-radio, .form-checkbox {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      position: relative;
      background-color: #f1f1f1;
      color: $main-color;
      top: 10px;
      height: 30px;
      width: 30px;
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      margin-right: 7px;
      outline: none;

      &:checked::before {
        position: absolute;
        font: 13px/1 'Open Sans', sans-serif;
        left: 11px;
        top: 7px;
        content: '\02143';
        transform: rotate(40deg);
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &:checked {
        background-color: #f1f1f1;
      }
    }

    .form-checkbox {
      border-radius: 5px;
    }

    input[type="number"], textarea {
      background-color: #f1f1f1;
      border: 0;
      border-radius: 5px;
      padding: 0.5em;
      font-size: 15px;
    }

    input[type="number"] {
      width: 65px;
    }

    textarea {
      width: 400px;
      max-width: 100%;
    }

    label {
      font: 300 16px/1.7 'Open Sans', sans-serif;
      color: white;
      cursor: pointer;
    }
  }

  #post-signup-success {
    color: greenyellow;
    text-align: left;
    margin-left: 2em;
    transform: translateY(2em);
    opacity: 0;
    transition: 0.5s ease;
    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
