#luxbar {
  box-shadow: none;
  position: absolute;
}

.luxbar-menu {
  background: transparent;
  padding: 72px 0;
  @media (min-width: 992px) {
    height: auto !important;
  }
  @media (max-width: 767px) {
    background-color: $main-color;
  }
}

.luxbar-navigation {
  max-width: 1228px;
  margin: 0 auto;

  .luxbar-header {
    @media (max-width: 767px) {
      transform: translateY(-30px);
    }
  }

  .luxbar-item {
    padding: 0 25px;
    letter-spacing: 1px;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
